module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@raae/gatsby-remark-oembed/gatsby-browser.js'),
      options: {"plugins":[],"usePrefix":["oembed"],"providers":{"exclude":["Redit"]}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":10000,"linkImagesToOriginal":false,"quality":100,"withWebp":false,"showCaptions":true,"markdownCaptions":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":"0","icon":"<span>#</span>","className":"heading-anchor"},
    },{
      plugin: require('../node_modules/@pitayan/gatsby-theme-pitayan/gatsby-browser.js'),
      options: {"plugins":[],"siteAssets":"src/assets","postsPerPage":6,"mailChimpEndpoint":"https://no-code.us21.list-manage.com/subscribe/post?u=ff8f5620276ea4a487a03a7db&amp;id=50f8786e4e&amp;f_id=00b8e4e1f0","mailChimpTimeout":3500},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
