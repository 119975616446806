exports.components = {
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-pages-404-index-tsx": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/pages/404/index.tsx" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-pages-404-index-tsx" */),
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-pages-authors-index-tsx": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/pages/authors/index.tsx" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-pages-authors-index-tsx" */),
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-pages-categories-index-tsx": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/pages/categories/index.tsx" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-pages-categories-index-tsx" */),
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-pages-index-tsx": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/pages/index.tsx" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-pages-index-tsx" */),
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-all-posts-index-tsx": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/templates/allPosts/index.tsx" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-all-posts-index-tsx" */),
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-author-posts-index-tsx": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/templates/authorPosts/index.tsx" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-author-posts-index-tsx" */),
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-category-posts-index-tsx": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/templates/categoryPosts/index.tsx" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-category-posts-index-tsx" */),
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-post-index-tsx-content-file-path-workspace-content-posts-build-mvp-with-no-code-index-mdx": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/templates/post/index.tsx?__contentFilePath=/workspace/content/posts/build-mvp-with-no-code/index.mdx" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-post-index-tsx-content-file-path-workspace-content-posts-build-mvp-with-no-code-index-mdx" */),
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-post-index-tsx-content-file-path-workspace-content-posts-design-freedom-in-no-code-platforms-index-mdx": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/templates/post/index.tsx?__contentFilePath=/workspace/content/posts/design-freedom-in-no-code-platforms/index.mdx" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-post-index-tsx-content-file-path-workspace-content-posts-design-freedom-in-no-code-platforms-index-mdx" */),
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-post-index-tsx-content-file-path-workspace-content-posts-how-long-it-takes-to-build-mvp-with-no-code-index-mdx": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/templates/post/index.tsx?__contentFilePath=/workspace/content/posts/how-long-it-takes-to-build-mvp-with-no-code/index.mdx" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-post-index-tsx-content-file-path-workspace-content-posts-how-long-it-takes-to-build-mvp-with-no-code-index-mdx" */),
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-post-index-tsx-content-file-path-workspace-content-posts-no-code-low-code-use-cases-index-mdx": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/templates/post/index.tsx?__contentFilePath=/workspace/content/posts/no-code-low-code-use-cases/index.mdx" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-post-index-tsx-content-file-path-workspace-content-posts-no-code-low-code-use-cases-index-mdx" */),
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-post-index-tsx-content-file-path-workspace-content-posts-no-code-platforms-in-enterprises-index-mdx": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/templates/post/index.tsx?__contentFilePath=/workspace/content/posts/no-code-platforms-in-enterprises/index.mdx" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-post-index-tsx-content-file-path-workspace-content-posts-no-code-platforms-in-enterprises-index-mdx" */),
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-post-index-tsx-content-file-path-workspace-content-posts-what-is-no-code-index-mdx": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/templates/post/index.tsx?__contentFilePath=/workspace/content/posts/what-is-no-code/index.mdx" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-post-index-tsx-content-file-path-workspace-content-posts-what-is-no-code-index-mdx" */),
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-site-index-tsx-content-file-path-workspace-content-site-about-index-md": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/templates/site/index.tsx?__contentFilePath=/workspace/content/site/about/index.md" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-site-index-tsx-content-file-path-workspace-content-site-about-index-md" */),
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-site-index-tsx-content-file-path-workspace-content-site-privacy-policy-index-md": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/templates/site/index.tsx?__contentFilePath=/workspace/content/site/privacy-policy/index.md" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-site-index-tsx-content-file-path-workspace-content-site-privacy-policy-index-md" */),
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-site-index-tsx-content-file-path-workspace-content-site-terms-and-conditions-index-md": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/templates/site/index.tsx?__contentFilePath=/workspace/content/site/terms-and-conditions/index.md" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-site-index-tsx-content-file-path-workspace-content-site-terms-and-conditions-index-md" */),
  "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-site-index-tsx-content-file-path-workspace-content-site-write-for-us-index-md": () => import("./../../../node_modules/@pitayan/gatsby-theme-pitayan/src/templates/site/index.tsx?__contentFilePath=/workspace/content/site/write-for-us/index.md" /* webpackChunkName: "component---node-modules-pitayan-gatsby-theme-pitayan-src-templates-site-index-tsx-content-file-path-workspace-content-site-write-for-us-index-md" */)
}

